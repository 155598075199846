import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company.model';
import { OfferPartialActivityMobile } from 'src/app/models/offer-partial-activity-mobile';
import { OfferPartialActivityMobileGoodPack } from 'src/app/models/offer-partial-activity-mobile-good-pack';
import { TransportTypes } from 'src/app/models/enums/transport-types';
import { Incoterms } from 'src/app/models/enums/incoterms';
import { SeaportMinimizedModel } from 'src/app/models/SeaportMinimized.model';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { SelectboxService } from 'src/app/services/selectbox.service';
import { SelectBoxItem } from 'src/app/models/select-box-item.model';
import { ActivityService } from 'src/app/services/activity.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Email } from '../../../../models/email.model';
import { EmailAttachment } from '../../../../models/email-attachment.model';
import EntityUtil from '../../../../models/framework/utils/entity-util';
import { PersonService } from 'src/app/services/person.service';
import { OfferPartialActivityMobileSeaCharge } from 'src/app/models/offer-partial-activity-mobile-sea-charge';
import { OfferPartialActivityMobileFrontEndTariff } from 'src/app/models/offer-partial-activity-mobile-front-end-tariff';
import { DistrictMinimizedModel } from 'src/app/models/district-minimized.model';
import { CityMinimizedModel } from 'src/app/models/city-minimized.model';
import { CountryMinimizedModel } from 'src/app/models/country-minimized.model';
import { MatDialog } from '@angular/material/dialog';
import { CreateCustomerComponent } from 'src/app/shared/create-customer/create-customer.component';
import { UpdateOfferStatusDTO } from 'src/app/models/update-offer-status-dto.model';
import { MinDTO } from 'src/app/models/min-dto.model';
import b64toBlob from 'b64-to-blob';
import { UserService } from 'src/app/services/user.service';
import { GoodDefinationMinimized } from 'src/app/models/good-defination-minimized';

export class reservationModel {
  senderId: MinDTO;
  consigneId: MinDTO;
  operationNote: string;
  customerRef: string;
  operationRepresentativeId: MinDTO;
  masterOnly: boolean;
  salesChannel: string;
  freightPayment: string;
  whoseBusiness: string;
  abroadAgentId: MinDTO;
  offerSeaDetailId: number;

}


@Component({
  selector: 'app-offer-request-partial',
  templateUrl: './offer-request-partial.component.html',
  styleUrls: ['./offer-request-partial.component.scss']
})
export class OfferRequestPartialComponent implements OnInit {


  @ViewChild('fubauto') uploader: any;

  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  transportTypes: string[] = Object.values(TransportTypes);
  incoterms: string[] = Object.values(Incoterms)
  seaports: SeaportMinimizedModel[] = [];
  filteredSeaports: SeaportMinimizedModel[] = [];

  filteredDistrictMinimized: DistrictMinimizedModel[] = [];
  filteredCityMinimized: CityMinimizedModel[] = [];
  filteredCountryMinimized: CountryMinimizedModel[] = [];
  filteredUsers: any[] = [];

  filteredGoodDefinationType:GoodDefinationMinimized[] = [];
  filteredGoodDefinationType2:GoodDefinationMinimized[] = [];

  currencyType: any[];
  currency: any[]
  invoiceItemType: any[] = [];

  serviceCodes: SelectBoxItem[] = [];
  offerTypes: SelectBoxItem[] = [];
  packingTypes: SelectBoxItem[] = [];
  tariffCalculateType: SelectBoxItem[] = [];
  isLoading: boolean = false;
  showReservationConfirmDialog: boolean = false;

  isGoodPackType: boolean = false;

  lastFilteredGoods: any [] = [];
  childGoods: any[] = [];

  stateOptions = [
    {label: 'offerRequest', value: 'offerRequest'},
    {label: 'reservation', value: 'reservation', disabled: true}
  ];
  SubTypeText = { 'pendingConfirm': 'Fiyatlandırma Bekleniyor.', 'confirmed': 'Fiyat Onaylandı', 'inProgressCompleted': 'Fiyatlandırma Yapıldı', 'accepted': 'Kabul Edildi', 'rejected': 'Red Edildi', 'revisionRequest': 'Revizyon Yapıldı', 'pricing': 'Fiyatlandırma Bekleniyor' }

  subTypes = ['Fiyat Pahalı', 'Araç Tedarik Edilemedi']
  rejectDescription: string;
  rejectSubType = null;
  clickCount = 0;
  pricingStatus = null;

  freightPaymentType: any[] = ['CC', 'PP'];
  activeTab: String = 'offerRequest';
  businessType: any[] = [{ type: 'B', name: 'Bizim' }, { type: 'A', name: 'Acenta' }, { type: 'O', name: 'Ortak' }];
  business: any[] = [{ type: 'B', name: 'Bizim' }, { type: 'A', name: 'Acenta' }, { type: 'O', name: 'Ortak' }];
  salesChannel: any[] = [{ code: 'ST', name: 'Sales Team' }, { code: 'BD', name: 'BusinessDevelopment' }, { code: 'TM', name: 'Telemarketing' }, { code: 'DM', name: 'Digital Marketing' }, { code: 'NVO', name: 'NVOCC' }];
  isStackable: any[] = [{ code: true, name: 'Evet' }, { code: false, name: 'Hayır' }]
  reportList: any = [];

  operationRepresentativeList: any[];
  operationRepresentative: any[];

  displayBasic = false;
  displayMail = false;
  context = '';
  attachment: any;
  reportId: number;
  file: any;
  mailTo: string[] = [];
  mailFrom = '';
  cc: string[] = [];
  bcc: string[] = [];
  subject: string;
  companyEmails: string[] = [];
  transportDirection: string = null;

  digitalStore: any = [];

  minIncoFareLcl: number = 0;
  minIncoAmount: number = 0;
  amountList: any[] = [];

  revisionDescription: string;
  showRevisionDescDialog: boolean = false;

  shipmentOrUnitOptions = [{ label: 'Shipment', value: 'shipment' }, { label: 'Unit', value: 'unit' }];
  frontEndTariffTypes = [{ label: 'Ön Taşıma', value: 'from' }, { label: 'Son Taşıma', value: 'to' }]
  frontEndLoadingTypes = [{ label: 'Adres', value: 'ADDRESS' }, { label: 'Depo', value: 'WAREHOUSE' }, { label: 'Liman', value: 'PORT' }, { label: 'Antrepo', value: 'FRONT' }]
  defaultShipmentOrUnit = 'shipment'

  offerPartialActivityMobile: OfferPartialActivityMobile = new OfferPartialActivityMobile();
  reservation: reservationModel = new reservationModel();

  seaGood: any;

  serviceCodeType: any[] = [{ name: 'Ağır Kargo', json: 'HEA' },
  { name: 'Bozulabilir Kargo', json: 'VUL' },
  { name: 'Canlı Hayvan', json: 'AVI' },
  { name: 'Cenaze', json: 'HUM' },
  { name: 'Diplomatik Kargo', json: 'DIP' },
  { name: 'Genel Kargo', json: 'GCR' },
  { name: 'Perishable Cargo', json: 'PER' },
  { name: 'Spor Silahları', json: 'SWP' },
  { name: 'Tehlikeli Madde', json: 'DGR' },
  { name: 'Valuable Cargo', json: 'VAL' }];
  serviceCode: any[] = [{ name: 'Ağır Kargo', json: 'HEA' },
  { name: 'Bozulabilir Kargo', json: 'VUL' },
  { name: 'Canlı Hayvan', json: 'AVI' },
  { name: 'Cenaze', json: 'HUM' },
  { name: 'Diplomatik Kargo', json: 'DIP' },
  { name: 'Genel Kargo', json: 'GCR' },
  { name: 'Perishable Cargo', json: 'PER' },
  { name: 'Spor Silahları', json: 'SWP' },
  { name: 'Tehlikeli Madde', json: 'DGR' },
  { name: 'Valuable Cargo', json: 'VAL' }];

  disableSave: boolean = false;
  isDisabledSales: boolean = false;

  constructor(private companyService: CompanyService, private offerExportService: OfferExportService, private datePipe: DatePipe,
    private selectBoxService: SelectboxService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private location: Location,
    private dialog: MatDialog,
    private userService: UserService,
    private personService: PersonService,
    private activityService: ActivityService) { }

  async ngOnInit() {
    this.transportDirection = location.pathname.includes("/activities/import/") ? 'I' : 'E'
    if (this.transportDirection === 'E') this.offerPartialActivityMobile.serviceCode = 'GCR';

    this.serviceCodes = await this.selectBoxService.getItemByBoxName('seaServiceCode').toPromise();
    this.offerTypes = await this.selectBoxService.getItemByBoxName('offerTypes').toPromise();
    this.packingTypes = await this.selectBoxService.getItemByBoxName('packingTypes').toPromise();
    this.tariffCalculateType = await this.selectBoxService.getItemByBoxName('seaTariffCalculateType').toPromise();

    this.offerExportService.getAllCurrency().subscribe(res => this.currency = res.body)


    if (this.route.snapshot.params.id) {
      this.getofferPartialActivity();
    } else {
      // default add goodpack
      this.addGoodPack();
      const user = this.userService.getUserByTransportDirection(this.transportDirection);
      this.offerPartialActivityMobile.salesRepresentative = user;
      var today = new Date();
      this.offerPartialActivityMobile.validityDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
    }
      await this.createNewGoodDefinationList();
      console.log(this.offerPartialActivityMobile.incoAmount)
  }

  createNewGoodDefinationList(){
    this.offerExportService.filterGoodDefinationType().subscribe(res=>{
      this.filteredGoodDefinationType = res.body
      let topGoodType = this.filteredGoodDefinationType.filter(good => good.topGoodType == null)
      topGoodType.forEach(res =>{
          this.filteredGoodDefinationType.forEach(good=>{
              if(good.topGoodType !=null && (good.topGoodType.id == res.id)){
                  this.childGoods.push(good);
              }
          })
      })
      this.childGoods.forEach(childGood => {
          this.filteredGoodDefinationType.forEach(good => {
              if(good.topGoodType != null && (childGood.id == good.topGoodType.id)){
                  this.lastFilteredGoods.push(good)
              }
          })
      })
        this.filteredGoodDefinationType = this.lastFilteredGoods
        this.filteredGoodDefinationType2 = this.lastFilteredGoods
      }
  )
}


  filterSeaport(event) {
    if (event.query.length < 3) return;
    this.offerExportService.getOfferExportSeaport(event.query).subscribe(res => {
      this.filteredSeaports = res.body;
    });
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.filteredCompanies = res;
    });
  }


  filterCurrency(event) {
    this.currencyType = [];
    this.currency.forEach(c => {
      if (c.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        this.currencyType.push(c);
      }
    });

  }

  filterInvoiceItem() {
    this.offerExportService.invoiceItemType('SEA', this.transportDirection).subscribe(res => {
      this.invoiceItemType = res.body;
    });
  }

  newGoodPack() {
    const pack = new OfferPartialActivityMobileGoodPack();
    return pack;

  }

  addGoodPack() {
    this.offerPartialActivityMobile.goodPacks.push(this.newGoodPack())
  }
  removeGoodPack(index) {
    this.offerPartialActivityMobile.goodPacks.splice(index, 1);
  }


  addSeaCharge() {
    if (!this.offerPartialActivityMobile.offerSeaCharges) this.offerPartialActivityMobile.offerSeaCharges = [];
    this.offerPartialActivityMobile.offerSeaCharges.push(new OfferPartialActivityMobileSeaCharge())
  }

  removeSeaCharge(index) {
    this.offerPartialActivityMobile.offerSeaCharges.splice(index, 1);
  }

  addFrontEndTariff() {
    if (!this.offerPartialActivityMobile.frontEndTariffs) this.offerPartialActivityMobile.frontEndTariffs = [];
    this.offerPartialActivityMobile.frontEndTariffs.push(new OfferPartialActivityMobileFrontEndTariff())
  }

  removeFrontEndTariff(index) {
    this.offerPartialActivityMobile.frontEndTariffs.splice(index, 1);
  }



  shipmentOrUnitOptionChanged() {
    this.offerPartialActivityMobile.isShipment = this.defaultShipmentOrUnit == 'shipment';
    if (this.defaultShipmentOrUnit == 'shipment') {
      if ((!this.offerPartialActivityMobile.goodPacks || this.offerPartialActivityMobile.goodPacks.length == 0) || !this.offerPartialActivityMobile.activity?.id) {
        this.offerPartialActivityMobile.goodPacks = [];
        this.addGoodPack();
      }
    }
  }


  async saveActivity(showAlert = true) {
    let isValid = true;
    if (this.defaultShipmentOrUnit === 'shipment') {
      this.offerPartialActivityMobile?.goodPacks.forEach(goodpack => {
        if (this.isValid(goodpack, [{ name: 'volume', label: 'CBM' }, { name: 'quantity', label: 'Adet' }, { name: 'type', label: 'Yük Tipi' }, { name: 'grossWeight', label: 'Brüt Ağırlık' }])) {
        } else {
          isValid = false;
        }

      });
    } else {
      this.offerPartialActivityMobile?.goodPacks.forEach(goodpack => {
        if (this.isValid(goodpack, [{ name: 'quantity', label: 'Adet' }, { name: 'type', label: 'Yük Tipi' }, { name: 'length', label: 'Uzunluk' }, { name: 'width', label: 'Genişlik' }, { name: 'height', label: 'Yükseklik' }, { name: 'grossWeight', label: 'Brüt Ağırlık' }])) {
        } else {
          isValid = false;
        }
      });
    }
    if(this.offerPartialActivityMobile?.activity?.id && !this.offerPartialActivityMobile?.packType && !this.isGoodPackType){
      isValid = false;
      this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
      console.log('eski')
    }
    if(this.offerPartialActivityMobile?.activity?.id && !this.offerPartialActivityMobile?.goodPackType && this.isGoodPackType){
      isValid = false;
      this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
      console.log('yeni')
    }
    if(!this.route.snapshot.params.id && this.offerPartialActivityMobile?.goodPackType == null){
      isValid = false;
      this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
      console.log('yeni olusturma')
    }

    if((this.offerPartialActivityMobile.incoterm === 'EXW' || this.offerPartialActivityMobile.incoterm === 'DDP' || this.offerPartialActivityMobile.incoterm === 'DDU'
    || this.offerPartialActivityMobile.incoterm === 'DAP') && (this.offerPartialActivityMobile.transportType === 'DTP' || this.offerPartialActivityMobile.transportType === 'DTD')){
      if(this.offerPartialActivityMobile.frontEndTariffs?.length == 0 || this.offerPartialActivityMobile.frontEndTariffs === null){
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Lütfen ön/son taşıma bilgisi ekleyin.' });
        return;
      }
      if(this.offerPartialActivityMobile.frontEndTariffs?.length > 0 || this.offerPartialActivityMobile.frontEndTariffs !== null){
        this.offerPartialActivityMobile.frontEndTariffs.forEach(tariff =>{
          if(tariff.address === null || tariff.address === '' || tariff.address === undefined){
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Lütfen adres alanını doldurun.' });
            return;
          }
        })
      }
    }

    if (isValid && this.isValid(this.offerPartialActivityMobile, [
      { name: 'stackable', label: 'İstiflenebilir' },
      { name: 'offerType', label: 'Teklif Tipi' },
      { name: 'amount', label: 'Tutar' },
      { name: 'currency', label: 'Tahmini Para Birimi' }])) {
      this.isLoading = true;
      try {
          console.log(this.offerPartialActivityMobile)
        const offerPartialActivityMobile = await this.activityService.offerPartialActivity(this.offerPartialActivityMobile).toPromise();
        await this.getofferPartialActivity(offerPartialActivityMobile.activity.id);
        this.offerPartialActivityMobile.validityDate = new Date(this.offerPartialActivityMobile.validityDate);

        if (!this.route.params['id'] && !this.route.snapshot.params.id) {
          this.locationChange();
        }

        if (showAlert) {
          this.messageService.add({ key: 'tst', severity: 'success', summary: '', detail: 'Kaydedildi' });
        }

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    }
  }

  locationChange() {
    this.location.go(`${this.location.path(false)}/${this.offerPartialActivityMobile.activity.id}`);
    this.route.params['id'] = this.offerPartialActivityMobile.activity.id;
  }

  async getofferPartialActivity(id = this.route.snapshot.params.id) {

    this.offerPartialActivityMobile = await this.activityService.getOfferPartialActivity(id).toPromise();

    this.isGoodPackType = this.offerPartialActivityMobile?.goodPackType ? true : false;

    if (this.offerPartialActivityMobile.validityDate) {
      this.offerPartialActivityMobile.validityDate = new Date(this.offerPartialActivityMobile.validityDate);
    }
    this.rejectSubType = this.offerPartialActivityMobile.offerSubStatusCode;

    this.offerPartialActivityMobile?.offerSeaCharges?.forEach(seaCharge => this.amountList.push({ id: seaCharge?.id, amount: seaCharge?.amount }));
    this.minIncoAmount = this.offerPartialActivityMobile.incoAmount;
    this.minIncoFareLcl = this.offerPartialActivityMobile.incoFareLcl;
    this.defaultShipmentOrUnit = this.offerPartialActivityMobile.isShipment ? 'shipment' : 'unit';

    this.pricingStatus = this.offerPartialActivityMobile.status;

    if ((this.offerPartialActivityMobile.incoFareLcl == null || this.offerPartialActivityMobile.incoFareLcl === 0) || (this.offerPartialActivityMobile.incoAmount == null || this.offerPartialActivityMobile.incoAmount === 0)){
        this.isDisabledSales = true;
    }

    if (this.offerPartialActivityMobile.seaGoods && this.offerPartialActivityMobile.seaGoods.length > 0) {
      const lastSeaGood = this.offerPartialActivityMobile.seaGoods.pop();
      this.reservation.abroadAgentId = lastSeaGood.abroadAgentId;
      this.reservation.senderId = lastSeaGood.senderId;
      this.reservation.consigneId = lastSeaGood.consigneId;
      this.reservation.freightPayment = lastSeaGood.freightPayment;
      this.reservation.masterOnly = lastSeaGood.masterOnly;
      this.reservation.operationNote = lastSeaGood.operationNote;
      this.reservation.customerRef = lastSeaGood.customerRef;
      this.reservation.operationRepresentativeId = lastSeaGood.operationRepresentativeId;
      this.reservation.salesChannel = lastSeaGood.salesChannel;
      this.reservation.whoseBusiness = lastSeaGood.whoseBusiness;
      this.seaGood = lastSeaGood;
    }

    this.activeRezervationButton();

  }

  onBlurIncoAmountUnitInput() {
    this.disableSave = true;
    setTimeout(() => {
      let disableNum = 0;
      if (this.minIncoAmount > this.offerPartialActivityMobile.incoAmount) {
        disableNum++;
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar en az ${this.minIncoAmount} olmalıdır` });
      }
      if (this.minIncoFareLcl > this.offerPartialActivityMobile.incoFareLcl) {
        disableNum++;
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar en az ${this.minIncoFareLcl} olmalıdır` });
      }
      if (disableNum === 0) this.disableSave = false;
    }, 500);

  }

  setValidityDate(d: Date) {


    this.offerPartialActivityMobile.validityDate = new Date(d.setDate(d.getDate() + 1))

    // d.setTime(d.getTime() + (new Date().getTimezoneOffset() * 60 * 1000));
    this.offerPartialActivityMobile.validityDate = d;
  }

  getReportList() {
    this.mailFrom = JSON.parse(localStorage.getItem('user')).email;
    if (this.reportList?.length >= 0) {
      this.offerExportService.getReportList(this.offerPartialActivityMobile.offer.id).subscribe(rp => {
        this.reportList = rp.body;
      });
    }
  }

  filterDistrict(event, cityId?: any) {
    if (event?.query?.length > 2 && cityId) {
      this.offerExportService.getOfferExportDistrict(event.query, cityId, this.transportDirection).subscribe(res => {
        this.filteredDistrictMinimized = res.body;
      });
    }
  }

  filterCity(event, countryId?: any) {
    if (event.query.length > 2) {
      this.offerExportService.getOfferExportCity(event.query, countryId, this.transportDirection).subscribe(res => {
        this.filteredCityMinimized = res.body;
      });
    }
  }

  filterGoodDefinationType(event){
    const filtered: any[] = [];
        const query = event.query;
        this.filteredGoodDefinationType.forEach(goodTypeDef => {
            if (goodTypeDef.name.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(goodTypeDef);
            }
        });
        this.filteredGoodDefinationType2 = filtered;
}

  filterCountry(event) {
    if (event.query.length > 2) {
      this.offerExportService.getOfferExportCountry(event.query, this.transportDirection).subscribe(res => {
        this.filteredCountryMinimized = res.body;
      });
    }
  }
  filterOperationRepresentative(event) {
    this.offerExportService.getUserWithName(event.query, this.transportDirection).subscribe(res => {
      this.operationRepresentativeList = res.body;
    });
  }

  filterBusinessType(event) {
    const filtered: any[] = [];
    const query = event.query;
    this.business.forEach(country => {
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(country);
      }
    });
    this.businessType = filtered;
  }


  addPdfAsAttachment(report: any) {
    this.isLoading = true;

    if (this.offerPartialActivityMobile.customer) {
      this.personService.search({ 'company.id': this.offerPartialActivityMobile.customer.id }, this.transportDirection).subscribe(persons => {
        this.companyEmails = this.companyEmails.concat(persons.body.filter(f => f.email).map(q => q.email));

        this.companyService.getById(this.offerPartialActivityMobile.customer.id, this.transportDirection).subscribe(c => {
          this.companyEmails = this.companyEmails.concat(c.body.defaultAddresCardDetail.emails.map(e => e.name));
        })
      });
    }

    const renderedParams = {
      entityId: this.offerPartialActivityMobile.customer.id,
      companyTypeCode: 'customer',
      pageId: report?.page?.id
    };
    this.offerExportService.getEMailObjectsByParams(JSON.stringify(renderedParams), this.transportDirection).subscribe(res => {
      if (!this.mailTo?.length) {
        if (!res.body[0].email) {
          this.messageService.add({
            key: 'tst',
            severity: 'error',
            summary: 'Uyarı',
            detail: 'Şirket e-posta adresi bulunamadı.'
          });
          return;
        }
        this.mailTo.push(res.body[0].email);
      }
    });
    this.displayMail = !this.displayMail;
    this.offerExportService.getReport(report?.id, this.offerPartialActivityMobile?.offer?.id).subscribe(gt => {
      this.isLoading = false;
      const file = new Blob([gt], { type: 'application/pdf' });
      this.file = file;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Eklendi' });
    }, error => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'PDF Eklenemedi!' });
      this.isLoading = false;
    });
    this.offerExportService.getEmailSettingsByID(report?.id, this.offerPartialActivityMobile?.offer?.id, this.transportDirection).subscribe(doc => {
      this.context = doc?.body?.mailBody;
      this.subject = doc?.body?.mailSubject;
    });
  }

  showPdf(report: any) {
    this.isLoading = true;
    this.offerExportService.getReport(report?.id, this.offerPartialActivityMobile?.offer?.id).subscribe(gt => {
      this.isLoading = false;
      const file = new Blob([gt], { type: 'application/pdf' });
      this.file = file;
      const fileUrl = window.URL.createObjectURL(file);
      window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
    }, error => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
      this.isLoading = false;
    });
  }

  sendReportMail() {
    this.isLoading = true;
    const email: Email = new Email();
    email.fromAddress = JSON.parse(localStorage.getItem('user')).email;;
    email.user = JSON.parse(localStorage.getItem('user'));
    email.toAddress = this.mailTo?.toString();
    email.ccAddress = this.cc?.toString();
    email.bccAddress = this.bcc?.toString();
    email.subject = this.subject;
    email.body = this.context;

    if (!email.toAddress?.length || email.subject === '' || email.body === '') {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Alıcı, Konu ve içerik boş olamaz!' });
      this.isLoading = false;
    } else {
      const attachment: EmailAttachment = new EmailAttachment();
      let emailAttachment: EmailAttachment;
      let uploadedAttachment: EmailAttachment;
      this.offerExportService.saveEmail(email).subscribe(res => {
        attachment.email = EntityUtil.toSimple(res.body);
        attachment.createDate = new Date();
        attachment.fileName = 'Teklif' + '.pdf';
        attachment.fileContentType = '.pdf';
        attachment.fileExtension = '.pdf';
        attachment.name = 'Teklif' + '.pdf';
        attachment.reportId = this.reportId;
        this.attachment = attachment;

        this.offerExportService.saveAttachment(this.attachment).subscribe(atc => {
          emailAttachment = atc.body;
          this.file = new File([this.file], 'fileName');
          const formData: FormData = new FormData();
          formData.append('file', this.file);
          this.offerExportService.uploadAttachment(formData, atc.body.id).subscribe(up => {
            uploadedAttachment = up.body;
            this.offerExportService.sendEmail(res.body.id).subscribe(ans => {
              this.isLoading = false;
              this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Mail Gönderildi!' });
              this.displayMail = false;
            }, error => {
              this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Konu ve içerik boş olamaz!' });
              this.isLoading = false;
            });
          });
        });
      });
    }
  }

  autocompleteFocusOut(value, key, form, field = 'id') {
    if ((value && !value[field]) || value?.name === undefined) form.get(key).setValue(null);
  }

  showCreateCustomerDialog() {
    let dialogRef = this.dialog.open(CreateCustomerComponent, {
      panelClass: 'modal-md',
      width: '1000px',
      height: 'auto',
      data: {}
    });
  }

  async createReservation() {

    const requiredList = [{ name: 'senderId', label: 'Gönderici' }, { name: 'operationRepresentativeId', label: 'Operasyon Temsilcisi' }, { name: 'salesChannel', label: 'Satış Kanalı' }, { name: 'whoseBusiness', label: 'İş Tipi' }];
    if (this.transportDirection !== 'E') {
      requiredList.push({ name: 'consigneId', label: 'Alıcı' });
    }
    console.log(requiredList, this.transportDirection)
    if (!this.isValid(this.reservation, requiredList)) {
      return;
    }
    console.log(this.offerPartialActivityMobile)

    if((this.offerPartialActivityMobile.incoFareLcl === 0 || this.offerPartialActivityMobile.incoFareLcl == undefined) && (this.offerPartialActivityMobile.incoAmount === 0 || this.offerPartialActivityMobile.incoAmount == undefined)){
      this.messageService.add({ key: 'tst', severity: 'warn', summary: '', detail: 'Navlun birim veya toplam tutar fiyatı giriniz'});
      return;
    }

    this.isLoading = true;

    const reservationPayload = {};
    reservationPayload['offerSeaDetailId'] = this.offerPartialActivityMobile.offerSeaDetail.id;
    reservationPayload['consigneId'] = this.reservation?.consigneId ? this.reservation?.consigneId["id"] : null;
    reservationPayload['senderId'] = this.reservation?.senderId ? this.reservation?.senderId["id"] : null
    reservationPayload['abroadAgentId'] = this.reservation?.abroadAgentId ? this.reservation?.abroadAgentId["id"] : null
    reservationPayload['operationRepresentativeId'] = this.reservation?.operationRepresentativeId ? this.reservation?.operationRepresentativeId["id"] : null
    reservationPayload['operationNote'] = this.reservation.operationNote;
    reservationPayload['customerRef'] = this.reservation.customerRef;
    reservationPayload['masterOnly'] = this.reservation.masterOnly;
    reservationPayload['salesChannel'] = this.reservation.salesChannel;
    reservationPayload['freightPayment'] = this.reservation.freightPayment;
    reservationPayload['whoseBusiness'] = this.reservation.whoseBusiness;

    const resp = await this.offerExportService.createReservation(reservationPayload, this.transportDirection)
    if (resp.status === 200) this.messageService.add({ key: 'tst', severity: 'success', summary: '', detail: 'Kaydedildi' });

    await this.saveActivity(false);
    this.isLoading = false;

  }


  isValid(values: any, required: any[]) {
    const invalid = [];
    console.log(required, values)

    required.forEach(req => {
      console.log(req, "reqqq")
      if (values[req.name] === null || values[req.name] === undefined || values[req.name] === '') {
        invalid.push(req.label);
      }

    });

    if (invalid.length > 0) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: '', detail: `${invalid[0]} zorunlu alandır.` });
    }
    return invalid.length == 0
  }

  activeRezervationButton() {
    // Tab Button durumu güncelle.
    this.stateOptions = [
      {label: 'offerRequest', value: 'offerRequest'},
      {label: 'reservation', value: 'reservation', disabled:  ['confirmed'].includes(this.offerPartialActivityMobile.status) ? false : true  }
    ];
  }
  toggleRevisionDialog() {
    this.showRevisionDescDialog = !this.showRevisionDescDialog;
  }

  async sendRevision() {
    const updateOfferStatusDTO = new UpdateOfferStatusDTO();
    updateOfferStatusDTO.offerSeaDetailId = this.offerPartialActivityMobile.offerSeaDetail.id;
    updateOfferStatusDTO.description = this.revisionDescription;
    updateOfferStatusDTO.status = 'revisionRequest';
    updateOfferStatusDTO.substatus = null;

    try {
      this.isLoading = true;
      await this.activityService.updateOfferStatus([updateOfferStatusDTO]).toPromise();
      this.messageService.add({ key: 'tst', severity: 'success', summary: '', detail: 'Kaydedildi' });
      this.getofferPartialActivity();
      this.isLoading = false;

    } catch {
      this.isLoading = false;
    }

  }

  autocompleteFocusOutFrontEndTariffs(key: any) {
    this.offerPartialActivityMobile.frontEndTariffs.forEach(value => {
      if (value[key] === '' || !value[key]?.id) {
        console.log(key, value[key]);
        value[key] = null;
      }
    })
  }


  showReservationPreviewDialog() {
      this.showReservationConfirmDialog = !this.showReservationConfirmDialog;
  }
  onClickOffer() {
    this.clickCount++;
    setTimeout(() => {
      if (this.clickCount === 1) {
        this.pricingStatus = this.pricingStatus == 'rejected' ? 'confirmed' : 'rejected';
      } else if (this.clickCount === 2) {
        this.pricingStatus = this.pricingStatus == 'rejected' ? 'confirmed' : 'rejected';
      }
      this.clickCount = 0;
    }, 250)
  }
  async onUpdateStatus() {
    if (this.pricingStatus === 'rejected' && !this.rejectSubType) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Lütfen iptal nedenini belirtin!' });
      return;
    }
    const updateOfferStatusDTO = new UpdateOfferStatusDTO();
    updateOfferStatusDTO.offerSeaDetailId = this.offerPartialActivityMobile.offerSeaDetail.id;
    updateOfferStatusDTO.description = this.rejectDescription
    updateOfferStatusDTO.status = this.pricingStatus
    updateOfferStatusDTO.substatus = this.pricingStatus == 'rejected' ? this.rejectSubType : null;

    try {
      this.isLoading = true;
      await this.activityService.updateOfferStatus([updateOfferStatusDTO]).toPromise();
      this.messageService.add({ key: 'tst', severity: 'success', summary: '', detail: 'Kaydedildi' });
      this.getofferPartialActivity();
      this.isLoading = false;

    } catch {
      this.isLoading = false;
    }
  }


  getDigitalArsiv() {
    this.isLoading = true;
    this.offerExportService.getDigitalArsiv(this.offerPartialActivityMobile.offer.id, 'Offer').subscribe(res => {
      this.digitalStore = res.body;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  getDigitalDocument(report: any) {
    this.isLoading = true;
    this.offerExportService.getDocumentType(report?.id, 'E').subscribe(res => {
      this.offerExportService.getDigitalDocument(res.body.reference, 'E').subscribe(gt => {
        this.isLoading = false;
        const file = b64toBlob(gt.body.obj.file.data, res.body.contentType);
        const fileUrl = window.URL.createObjectURL(file);
        if (res.body.contentType === 'application/pdf' || res.body.contentType.startsWith('image')) {
          window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
        } else {
          const nav = (window.navigator as any)
                    if (nav && nav.msSaveOrOpenBlob) {
                        nav.msSaveOrOpenBlob(gt.body.obj.file.data, res.body.contentType);
                    } else {
            var a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.download = res.body.fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
      }, error => {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
        this.isLoading = false;
      });
    }, error => {
      this.isLoading = false;
    });
  }

  uploadDocumentsCheck(documents: any) {
    console.log(documents);
    if (documents.files && documents.files.length > 0) {
      for (const file of documents.files) {
        if (file.size <= 15728640) {
          this.uploadDocuments(file);
        } else {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.getAsFile().size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
        }
      }

    } else if (documents.files && documents.files.length > 0) {
      for (const file of documents.files) {
        if (file.getAsFile().size <= 15728640) {
          this.uploadDocuments(file);
        } else {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
        }
      }
    }
  }

  uploadDocuments(file: any) {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', file);
    this.offerExportService.uploadDocuments(formData, this.offerPartialActivityMobile.offer.id).subscribe(res => {
      const dct = res.body.res;
      this.offerExportService.getPagesByName('/offers/offer').subscribe(pages => {
        const pageObject = pages.body.find(pg => pg.name === 'Teklif');
        if (res.status === 200) {
          this.offerExportService.saveDocument({
            entityid: +dct.entityid,
            reference: dct.uid,
            contentType: dct.contentType,
            fileName: dct.fileName,
            fileSize: dct.fileSize,
            page: pageObject,
            sourceType: 'Offer'
          }).subscribe(sv => {
            this.getDigitalArsiv();
            this.uploader.clear();
          });
          // this.user = this.cacheService.get(Storage.USER);
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak Başarıyla Kaydedildi' });
        }
      }, error => {
        this.isLoading = false;
      });
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  checkOfferSeaChargesMinAmount(event, val) {
    if (this.offerPartialActivityMobile?.offer?.id) {
      const offerSeaChargeAmount = this.amountList.find(c => c.id === val.id);
      if (event < offerSeaChargeAmount?.amount) {
        this.disableSave = true;
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar en az ${offerSeaChargeAmount.amount} olmalıdır` });
        setTimeout(() => {
          val.amount = offerSeaChargeAmount.amount
          this.disableSave = false;
        }, 500);
      } else {
        this.disableSave = false;
      }
    }
  }

  isDisableGoodPacks() {
    return this.route.snapshot.params.id && this.offerPartialActivityMobile.status !== 'pricing';
  }

  filterUsers(event) {
    if(event.query.lenght < 3) return;
    this.userService.searchUsers(event.query, this.transportDirection, 'ACTIVE').subscribe(res => this.filteredUsers = res);
  }

    async getAvgEmptyReturn(val: any) {

      if (this.offerPartialActivityMobile?.customer?.id && this.transportDirection == 'I'){
          this.isLoading = true;
          this.offerPartialActivityMobile.avgEmptyReturn = await this.companyService.getAvgEmptyReturn(this.offerPartialActivityMobile?.customer?.id, this.transportDirection).toPromise();
          this.isLoading = false;
      }
    }
}

